import { useEffect, useRef } from "react";
import './PrivacyPolicy.css'

const PrivacyPolicy = ({ setPrivacyPolicyOpen })=>{
    const closePrivacyPolicy = () => {
        setPrivacyPolicyOpen(false);
    };
    const modalRef = useRef();
  
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])

    useEffect(() => {
        const handler = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                setPrivacyPolicyOpen(false);
            }
        };
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    });

    return(
        <div className="PrivacyPolicyOUT">
            <div ref={ modalRef } className="PrivacyPolicy">
                <div className='PrivacyPolicyContent'>
                    <p onClick={ closePrivacyPolicy } className='CloseModal CP'>닫기</p>
                    <div className="PrivacyPolicyText">
                        <p className='ModalTitle'>개인정보처리방침</p>
                        <div className='ModalSection'>
                            <p className='ModalContent'>&#60; 법무법인 정곡 &#62;('http://lawfirmjk.co.kr/'이하 '법무법인 정곡 상담신청')은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.</p>
                            <p className='ModalContent'>○ 이 개인정보처리방침은 2023년 1월 1부터 적용됩니다.</p>
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>제1조(개인정보의 처리목적 및 보유기간)</p>
                            <p className='ModalSubTitle'>&#60; 법무법인 정곡 &#62;(이)가 개인정보 보호법 제32조에 따라 등록․공개하는 개인정보파일의 처리목적은 다음과 같습니다.<br />처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
                            <p className='ModalSubTitle ml-12'>1. 개인정보 파일명 : 개인정보처리</p>
                            <p className='ModalContent ml-12'>
                                개인정보의 처리목적 : 법률상담 신청 및 대응<br />
                                수집방법 : 홈페이지, 상담앱, 전화/팩스, 생성정보 수집 툴을 통한 수집<br />
                                보유근거 : 서비스 공급 및 고객 관리<br />
                                보유기간 : 1년
                            </p>
                            <p className='ModalSubTitle ml-12'>2. 처리하는 개인정보 항목</p>
                            <p className='ModalContent ml-12'>
                                &#60; 법무법인 정곡 &#62;은(는) 다음의 개인정보 항목을 처리하고 있습니다.<br />						
                                &#60; 홈페이지 및 상담앱 상담신청내역 및 결제관리 &#62;<br />
                                필수항목 : 이메일, 휴대전화번호, 이름, 서비스 이용 기록, 접속 로그, 접속 IP 정보<br />						
                                선택항목 : 쿠키<br />
                            </p>
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>제2조(개인정보의 제3자 제공에 관한 사항)</p>
                            <p className='ModalContent'>① &#60; 법무법인 정곡 &#62;은(는) 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</p>
                            <p className='ModalContent'>② &#60; 법무법인 정곡 &#62;은(는) 개인정보를 제3자에게 제공하고 있지 않습니다.</p>
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>제3조(개인정보처리의 위탁에 관한 사항)</p>
                            <p className='ModalContent'>① &#60; 법무법인 정곡 &#62;은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</p>
                            <p className='ModalContent ml-12'>
                                1. &#60; 위바이브 주식회사 &#62;&#60; 린케어 주식회사 &#62;<br />				
                                위탁받는 자 (수탁자) : 위바이브 주식회사, 린케어 주식회사<br />						
                                위탁하는 업무의 내용 : 위바이브 주식회사(CS), 린케어 주식회사(상담신청 시스템 및 DB 유지보수 관리, 보안<br />						
                                위탁 및 보유 기간 : 1년						
                            </p>
                            <p className='ModalContent ml-12'>
                                1. &#60; (주)알리는사람들 &#62;<br />				
                                위탁받는 자 (수탁자) : (주)알리는사람들<br />						
                                위탁하는 업무의 내용 : 상담접수 알림 발송<br />						
                                위탁 및 보유 기간 : 1년						
                            </p>
                            <p className='ModalContent'>② &#60; 법무법인 정곡 &#62;은(는) 위탁계약 체결시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.</p>
                            <p className='ModalContent'>③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</p>
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>제4조(개인정보의 파기절차 및 파기방법)</p>
                            <p className='ModalContent'>① &#60; 법무법인 정곡 &#62; 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</p>
                            <p className='ModalContent'>
                                ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.<br />							
                                1. 법령 근거 :<br />							
                                2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜		
                            </p>
                            <p className='ModalContent'>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
                            <p className='ModalContent'>
                                1. 파기절차<br />						
                                &#60; 법무법인 정곡 &#62; 은(는) 파기 사유가 발생한 개인정보를 선정하고, &#60; 법무법인 정곡 &#62; 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
                            </p>
                            <p className='ModalContent'>
                                2. 파기방법<br />						
                                전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다
                            </p>
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>제5조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)</p>
                            <p className='ModalContent'>
                                ① 정보주체는 법무법인 정곡에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
                            </p>                        
                            <p className='ModalContent'>
                                ② 제1항에 따른 권리 행사는법무법인 정곡에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 법무법인 정곡은(는) 이에 대해 지체 없이 조치하겠습니다.						
                            </p>                        
                            <p className='ModalContent'>
                                ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
                            </p>                        
                            <p className='ModalContent'>
                                ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
                            </p>                        
                            <p className='ModalContent'>
                                ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
                            </p>                        
                            <p className='ModalContent'>
                                ⑥ 법무법인 정곡은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
                            </p>                        
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>제6조(개인정보의 안전성 확보조치에 관한 사항)</p>
                            <p className='ModalSubTitle'>&#60; 법무법인 정곡 &#62;은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
                            <p className='ModalContent'>
                                &#60; 법무법인 정곡 &#62;은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
                            </p>                        
                            <p className='ModalContent'>
                                1. 개인정보 취급 직원의 최소화 및 교육<br />						
                                개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.						
                            </p>                        
                            <p className='ModalContent'>
                                2. 내부관리계획의 수립 및 시행<br />						
                                개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
                            </p>       
                            <p className='ModalContent'>
                                3. 개인정보의 암호화<br />
                                이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
                            </p>                                     
                            <p className='ModalContent'>
                                4. 접속기록의 보관 및 위변조 방지<br />						
                                개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다. 또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
                            </p>                        
                            <p className='ModalContent'>
                                5. 개인정보에 대한 접근 제한<br />						
                                개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
                            </p>                        
                            <p className='ModalContent'>
                                6. 문서보안을 위한 잠금장치 사용<br />						
                                개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.
                            </p>                        
                            <p className='ModalContent'>
                                7. 비인가자에 대한 출입 통제<br />						
                                개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
                            </p>                        
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>제7조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)</p>
                            <p className='ModalContent'>① 법무법인 정곡 은(는) 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</p>                        
                            <p className='ModalContent'>② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</p>                        
                            <p className='ModalContent'>가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</p>                        
                            <p className='ModalContent'>나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구 &#62; 인터넷 옵션 &#62; 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.	</p>                        
                            <p className='ModalContent'>다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</p>                                           
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>제8조 (개인정보 보호책임자에 관한 사항)</p>
                            <p className='ModalContent'>① 법무법인 정곡 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>                        
                            <p className='ModalContent ml-12'>
                                ▶ 개인정보 보호책임자<br />						
                                성명 :임영준<br />						
                                직책 :대표<br />						
                                직급 :변호사<br />						
                                연락처 :02-2602-7377, iyj@hanmail.net, 02-2602-7378(fax)
                            </p>                        
                            <p className='ModalContent'>※ 개인정보 보호 담당부서로 연결됩니다.</p>                        
                            <p className='ModalContent ml-12'>
                                ▶ 개인정보 보호 담당부서<br />						
                                부서명 :<br /> 						
                                담당자 : 임영준<br />						
                                연락처 : 02-2602-7377
                            </p>                        
                            <p className='ModalContent'>② 정보주체께서는 법무법인 정곡 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 법무법인 정곡 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</p>
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>제9조(개인정보의 열람청구를 접수·처리하는 부서)</p>
                            <p className='ModalSubTitle'>
                                정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.<br />						
                                &#60; 법무법인 정곡 &#62;은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다. 
                            </p>
                            <p className='ModalContent ml-12'>
                                ▶ 개인정보 열람청구 접수·처리 부서<br />						
                                부서명 :<br />						
                                담당자 : 임영준<br />						
                                연락처 : 02-2602-7377		
                            </p>                        
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>제10조(정보주체의 권익침해에 대한 구제방법)</p>
                            <p className='ModalContent'>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.</p>                        
                            <p className='ModalContent'>
                                1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)<br />						
                                2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)<br />						
                                3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br />						
                                4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)	
                            </p> 
                            <p className='ModalContent'>「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.</p> 
                            <p className='ModalContent ml-12'>※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.</p>                        
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>제11조(개인정보 처리방침 변경)</p>
                            <p className='ModalContent'>① 이 개인정보처리방침은 2023년 1월 1부터 적용됩니다.</p>                               
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PrivacyPolicy;