import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';
import ReservationService from "../services/reservation.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const Counsel = ({openModal, setStatus})=>{
    useEffect (()=>{
        setStatus('counsel')
    }, [])

    const navigate = useNavigate()
    const InputWrap = useRef();
    
    const currentDate = new Date();
    const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
    );
    const maxDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + 7
    )
    const dateToString = (date) => {
        return date.getFullYear() + (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0')
    }

    const [info, setInfo] = useState({
        name : "",
        phone : "",
        password : "",
        resdate : "",
        option : "",
        text : ""
    })
    const { name, phone, password, resdate, option, text } = info;

    const HandleInfo = (e)=>{
        setInfo({
            ...info,
            [e.target.name] : e.target.value
        })
    }

    const HandlePhone = (e)=>{
        e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
        setInfo({
            ...info,
            phone : e.target.value
        })
    }

    const HandlePassword = (e)=>{
        e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
        setInfo({
            ...info,
            password : e.target.value
        })
    }

    const ReservationBtn = ()=>{
        const merchant_uid = `mid_${new Date().getTime()}`
        if(name == ''){
            alert('이름을 입력해주세요');
            InputWrap.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            return;
        } 
        if (phone == ''){
            alert('전화번호를 입력해주세요');
            InputWrap.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            return;
        } 
        if (password == ''){
            alert('비밀번호를 입력해주세요');
            InputWrap.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            return;
        } 
        if (resdate == ''){
            alert('상담 희망 날짜를 선택해주세요');
            InputWrap.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            return;
        }
        if (option == ''){
            alert('상담분류를 입력해주세요');
            InputWrap.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            return;
        } 
        if (text == ''){
            alert('상담내용을 입력해주세요');
            InputWrap.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            return;
        } 
        ReservationService.reservation(
            name,
            phone,
            password,
            dateToString(resdate),
            option,
            text
        ).then((result)=>{
            navigate('/complete', {state : {
                name : name,
                phone : phone,
                password : password
            }})
        })   
    }

    return(
        <div className="Counsel">
            <div className="Container DF_Col">
                <h3 className="Title PO-R">신청 정보 <span className='QnAOpenBtn CP ml-12' onClick={ openModal }><FontAwesomeIcon icon={ faCircleInfo } /> 상담 QnA</span></h3>

                <div ref={ InputWrap } className="DF_Col">
                    <label className="SubTitle" htmlFor="name">성명</label>
                    <input className="Input" name="name" type="text" onChange={ HandleInfo } />
                </div>
                <div className="DF_Col">
                    <label className="SubTitle" htmlFor="phone">연락처</label>
                    <input className="Input" name="phone" type="text" maxLength="11" onChange={ HandlePhone } placeholder="'-' 없이 입력해주세요"/>
                </div>
                <div className="DF_Col">
                    <label className="SubTitle" htmlFor="password">비밀번호  </label>
                    <p className="Notice_2"><FontAwesomeIcon icon={ faCircleInfo } /> 상담 내역 조회 및 수정에 꼭 필요합니다.</p>
                    <input className="Input" name="password" type="text" maxLength="4" onChange={ HandlePassword } placeholder="숫자 4자리를 입력해주세요"/>
                </div>
                <div className="DF_Col">
                    <label className="SubTitle" htmlFor="date">상담 희망 날짜</label>
                    <DatePicker className="Input W100" 
                    dateFormat="yyyy-MM-dd" 
                    selected={ resdate } 
                    startDate={ startDate } 
                    minDate={ startDate } 
                    maxDate={ maxDate } 
                    onChange={ date => { 
                        setInfo({
                            ...info,
                            resdate : date
                        })
                    }} 
                    popperPlacement="auto"
                    locale={ko} />
                    
                </div>
                <div className="DF_Col">
                    <label className="SubTitle" htmlFor="option">상담 분류</label>
                    <select className="BoxRQ Select SelectPD" defaultValue="선택" onChange={ HandleInfo } name="option" id="">
                        <option value="선택" disabled>선택</option>
                        <option value="성폭력 / 성범죄">성폭력 / 성범죄</option>
                        <option value="부동산 / 임대차">부동산 / 임대차</option>
                        <option value="이혼 / 상속 / 재산분할">이혼 / 상속 / 재산분할</option>
                        <option value="민사">민사</option>
                        <option value="형사">형사</option>
                        <option value="기타 문의">기타 문의</option>
                    </select>
                </div>
                <div className="DF_Col">
                    <label className="SubTitle" htmlFor="text">상담 내용</label>
                    <textarea className="TextArea" name="text" id="" cols="30" rows="10"  onChange={ HandleInfo }></textarea>
                </div>
                <p className="Notice">상담 신청 완료시 사무소에서 일정 확인을 위해<br /><span className="bold">빠른 시일내 연락드립니다.</span></p>
                <button className="Btn CP" onClick={ ReservationBtn }>상담 신청</button>
            </div>
        </div>
    )
}
export default Counsel;