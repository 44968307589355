import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ReservationService from "../services/reservation.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Result = ({setStatus})=>{


    const startDate = new Date();
    const navigate = useNavigate();
    const dateToString = (date) => {
        return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0')
    }
    const location = useLocation();
    const [ openDate, setOpenDate ] = useState(false);
    const [ changeDate, setChangeDate ] = useState(startDate);
    const [ fixedDate, setFixedDate ] = useState();
    const [ data, setData ] = useState([{
        _id : "",
        name : location.state.name,
        phone : location.state.phone,
        reqdate : "",
        resdate : "",
        option : "",
        text : "",
        RVstatus : ""
    }])

    const { _id, name, phone, reqdate, resdate, option, text, RVstatus } = data;

    useEffect(()=>{
        const loadData = async()=>{
            await ReservationService.confirm(location.state.name, location.state.phone, location.state.password).then(result => {
                setData(result.data);
                setFixedDate(result.data.resdate)
                document.getElementById('TextArea').innerHTML = result.data.text;
            })
        }
        loadData();
    }, [])

    const CancelBtn = async ()=>{
        if(window.confirm('상담을 취소 하시겠습니까?')){
            await ReservationService.cancel({
                _id
            }).then(result => {                
                alert('상담 신청이 취소되었습니다.')
                navigate('/')
            }).catch(err =>{
                alert('취소 처리가 실패하였습니다. 재시도 해주세요.')
            })
        }
    }
    const UpdateBtn = ()=>{
        if(!openDate){
            ReservationService.update(
                _id,
                name,
                phone,
                resdate,
                option,
                text
            ).then(() => {
                alert('수정이 완료되었습니다')
                navigate('/')
            })
        }
        if(openDate){
            ReservationService.update(
                _id,
                name,
                phone,
                dateToString(resdate),
                option,
                text
            ).then(() => {
                alert('수정이 완료되었습니다')
                navigate('/')
            })
        }
    }
    return(
        <div className="Result">
            <div className="Container">
                <div className="Status">
                    <p className={
                        RVstatus == '신규상담'
                        ? "StatusActive StatusText"
                        : "StatusText"
                    }>신청완료</p>
                    <FontAwesomeIcon className={
                        RVstatus == '신규상담'
                        ? "StatusActive ClockIcon mt-2"
                        : "ClockIcon mt-2"
                    } icon={ faChevronRight } />
                    <p className={
                        RVstatus == '접수완료'
                        ? "StatusActive"
                        : "StatusText"
                    }>접수완료</p>
                    <FontAwesomeIcon className={
                        RVstatus == '접수완료'
                        ? "StatusActive ClockIcon mt-2"
                        : "ClockIcon mt-2"
                    } icon={ faChevronRight } />
                    <p>상담완료</p>
                </div>
                <h3 className="Title">신청 정보</h3>
                <div className="DF_Col">
                    <p className="SubTitle">성명</p>
                    <input className="Input" name="name" type="text" onChange={ (e)=>{
                        setData({
                            ...data,
                            name : e.target.value
                        })
                    }} defaultValue={ data.name } readOnly={
                        RVstatus == "상담 신청 완료"
                        ? false
                        : false
                    }/>
                </div>
                <div className="DF_Col">
                    <p className="SubTitle">연락처</p>
                    <input className="Input" name="phone" type="text" maxLength="11" onChange={ (e)=>{
                        e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
                        setData({
                            ...data,
                            phone : e.target.value
                        })
                    }} defaultValue={ data.phone }/>
                </div>
                <div className="DF_Col">
                    <p className="SubTitle">신청 날짜</p>
                    <div>
                        <input className="Input wd-60 mr-20" name="phone" type="text" defaultValue={ fixedDate } readOnly/>
                        <button className="BtnSmRQ CP" onClick={()=>{setOpenDate(!openDate)}}>날짜변경</button>
                    </div>
                    <div>
                    </div>
                    {
                        !openDate
                        ? null
                        :<div className="DF_Col">
                            <p className="SubTitle">변경 날짜</p>
                            <DatePicker className="Input W100" dateFormat="yyyy-MM-dd" selected={ changeDate } startDate={ startDate } minDate={ startDate } locale={ko} onChange={(date)=>{
                                setChangeDate(date)
                                setData({
                                    ...data,
                                    resdate : date
                                })
                            }}/>
                        </div>
                    }

                </div>
                <div className="DF_Col">
                    <p className="SubTitle">상담 분류</p>
                    <select className="BoxRQ Select SelectPD" name="option" id="" key={ data.option } defaultValue={ data.option }>
                        <option value="선택">선택</option>
                        <option value="성폭력 / 성범죄">성폭력 / 성범죄</option>
                        <option value="부동산 / 임대차">부동산 / 임대차</option>
                        <option value="이혼 / 상속 / 재산분할">이혼 / 상속 / 재산분할</option>
                        <option value="민사">민사</option>
                        <option value="형사">형사</option>
                        <option value="기타 문의">기타 문의</option>
                    </select>
                </div>
                <div className="DF_Col">
                <p className="SubTitle">상담 내용</p>
                <textarea className="TextArea" name="text" id="TextArea" cols="30" rows="10" onChange={(e)=>{
                    setData({
                        ...data,
                        text : e.target.value
                    })
                }}></textarea>
                </div>
                <div className="BtnSection mb-30">
                    <button onClick={ UpdateBtn } className="Btn_2 bg-blue CP mb-20">저장 <FontAwesomeIcon icon={ faCheck }/></button>
                    <p className="CancelBtn CP" onClick={ CancelBtn }>신청 취소</p>
                </div>
            </div>
        </div>
    )
}
export default Result;