import './Header.css'
import Banner from '../../assets/img/JungGok.png'

const Header = ()=>{
    return(
        <div className="Header">
            <div className="HeaderWrap Container">
                <img src={ Banner } alt="Banner" />
            </div>
        </div>
    )
}
export default Header;