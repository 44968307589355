import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ReservationService from "../services/reservation.service";
import './Confirm.css'

const Confirm = ({setStatus})=>{
    useEffect (()=>{
        setStatus('confirm')
    }, [])
    const navigate = useNavigate()
    const [ info, setInfo ] = useState({
        name : '',
        phone : '',
        password : ''
    })
    const { name, phone, password } = info
    const HandleInfo = (e)=>{
        setInfo({
            ...info,
            [e.target.name] : e.target.value})
    } 
    const HandlePhone = (e)=>{
        e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
        setInfo({
            ...info,
            phone : e.target.value
        })
    }
    const HandlePassword = (e)=>{
        e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
        setInfo({
            ...info,
            password : e.target.value
        })
    }
    const ConfirmBtn = ()=>{
        ReservationService.confirm(name, phone, password).then(result => 
            {
                if(result.data.message == "Fail"){
                    alert('신청 내역이 없습니다. 성명과 휴대폰 번호를 확인하세요.')
                } else if(result.data.message == "PasswordErr"){
                    alert('비밀번호가 일치하지 않습니다.')
                } else {
                    navigate('/result', {state : {
                        name : name,
                        phone : phone,
                        password : password
                    }})
                }
            })
    }

    return(
        <div className="Confirm">
            <div className="CFWrap Container">
                <h4 className="CFTitle"><span className="bold">본인 인증을 위해</span><br />성명과 연락처를 입력해주세요</h4>
                <label className="SubTitle" htmlFor="name">성명</label>
                <input className="Input mb-25" type="text" name="name" id="name" onChange={ HandleInfo }/>
                <label className="SubTitle" htmlFor="phone">연락처</label>
                <input className="Input mb-25" type="text" name="phone" id="phone" onChange={ HandlePhone } maxLength="11" placeholder="'-' 없이 입력해주세요"/>
                <label className="SubTitle" htmlFor="phone">비밀번호</label>
                <input className="Input mb-50" type="text" name="password" id="password" maxLength="4" onChange={ HandlePassword } placeholder="비밀번호 숫자 4자리를 입력해주세요"/>
                <button className="Btn_2 CP" onClick={ ConfirmBtn }>확인</button>
            </div>
        </div>
    )
}
export default Confirm;