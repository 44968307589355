import './Footer.css'

const Footer = ({ openPrivacyPolicy })=>{
    return(
        <div className="Footer">
            <div className="FooterWrap Container">
                <ul className='FooterMenu DF_Row'>
                    <li className='FTMenu DF_Row CP'><p onClick={ openPrivacyPolicy } >개인정보처리방침</p></li>
                </ul>
                <p className='FooterContent'>
                    상호: 법무법인 정곡 서울사무소<br />
                    대표자: 임영준 변호사<br />
                    주소: 서울시 양천구 신월로 387, 8층(신정동, 유앤미법조빌딩)<br />
                    전화: 02-2602-7377        이메일: iyj@hanmail.net
                </p>
            </div>
        </div>
    )
}
export default Footer;