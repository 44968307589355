import api from "./api";

class ReservationService {
  reservation(
    name,
    phone,
    password,
    resdate,
    option,
    text
  ){
    return api.post("/reservation/reservation", {
        name,
        phone,
        password,
        resdate,
        option,
        text
    })
  }

  confirm(
    name, phone, password
  ){
    return api.post("/reservation/confirm", {
        name, phone, password
    });
  }

  update(
    _id,
    name,
    phone,
    resdate,
    option,
    text
  ){
    return api.post("/reservation/update", {
      _id,
      name,
      phone,
      resdate,
      option,
      text
  });
  }
  cancel(
    _id
  ){
    return api.post("/reservation/cancel", {
      _id
  });
  }
}

export default new ReservationService();
