import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReservationService from "../services/reservation.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router";
import { faChevronRight, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

const Complete = ({setStatus})=>{

    const navigate = useNavigate();
    const location = useLocation();
    console.log(location)
    const [ data, setData ] = useState([{
        _id : "",
        name : location.state.name,
        phone : location.state.phone,
        password : location.state.password,
        reqdate : "",
        resdate : "",
        option : "",
        text : "",
        RVstatus : ""
    }])
    const { _id, name, phone, password, reqdate, resdate, option, text, RVstatus } = data;

    useEffect (()=>{
        setStatus('counsel')
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])
    useEffect(()=>{
        const loadData = async()=>{
            await ReservationService.confirm(location.state.name, location.state.phone, location.state.password).then(result => {
                setData(result.data);
            })
        }
        loadData();
    }, [])
    const ResultBtn=()=>{
        navigate('/result', {state : {
            name : name,
            phone : phone,
            password : password
        }})
    }

    return(
        <div className="Complete">
            <div className="Container DF_Col">
                <div className="Status">
                    <p className={
                        RVstatus == '신규상담'
                        ? "StatusActive StatusText"
                        : "StatusText"
                    }>신청완료</p>
                    <FontAwesomeIcon className={
                        RVstatus == '신규상담'
                        ? "StatusActive ClockIcon mt-2"
                        : "ClockIcon mt-2"
                    } icon={ faChevronRight } />
                    <p className={
                        RVstatus == '접수완료'
                        ? "StatusActive"
                        : "StatusText"
                    }>접수완료</p>
                    <FontAwesomeIcon className={
                        RVstatus == '접수완료'
                        ? "StatusActive ClockIcon mt-2"
                        : "ClockIcon mt-2"
                    } icon={ faChevronRight } />
                    <p>상담완료</p>
                </div>
                <div className="NoticeSection">
                    <h3 className="Title_2">상담 신청이 완료되었습니다.</h3>
                    <p className="Notice_2">정확한 상담일정 조정을 위해 사무실에서<br />빠른시일 내에 연락을 드립니다.</p>
                    <p className="Alert"><FontAwesomeIcon icon={ faCircleExclamation }/> 접수완료 시 상담신청 수정 및 취소가 불가합니다.</p>
                </div>
                <h3 className="Title">신청 정보</h3>
                <div className="DF_Row mb-40">
                    <label className="SubTitle_3" htmlFor="name">성명</label>
                    <p className="SubContent">{ name }</p>
                </div>
                <div className="DF_Row mb-40">
                    <label className="SubTitle_3" htmlFor="phone">연락처</label>
                    <p className="SubContent">{ phone }</p>
                </div>
                <div className="DF_Row mb-20">
                    <label className="SubTitle_3" htmlFor="date">신청날짜</label>
                    <p className="SubContent_2">{ resdate }</p>      
                </div>
                <div className="DF_Row mb-20 bg-ivory">
                    <label className="SubTitle_3" htmlFor="option">상담 분류</label>
                    <p className="SubContent">{ option }</p>
                </div>
                <div className="DF_Col mb-50">
                    <label className="SubTitle_3 mb-12" htmlFor="text">상담 내용</label>
                    <p className="SubContent_3 bd-br">{ text }</p>
                </div>
                <p className="Notice">상담 신청 완료시 사무소에서 일정 확인을 위해<br /><span className="bold">빠른 시일내 연락드립니다.</span></p>
                <button className="Btn_2 CP" onClick={ ResultBtn }>수정</button>
            </div>
        </div>
    )
}
export default Complete;