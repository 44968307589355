import axios from "axios";

const instance = axios.create({
  baseURL: "https://counsel.lawfirmjk.co.kr/api",
  // baseURL: "http://localhost:8080/api",
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
