import { useState } from "react";
import { Link } from "react-router-dom";
import './Navbar.css'
const Navbar = ({status, setStatus})=>{
    return(
        <div className="Navbar">
            <div className="Container MenuLists">
                <Link className={
                    status == 'counsel'
                    ? "Menu Active"
                    : "Menu"
                } to='/counsel' onClick={ ()=>{
                    setStatus('counsel')
                }}>상담 신청</Link>
                <Link className={
                    status == 'confirm'
                    ? "Menu Active"
                    : "Menu"
                } to='/confirm'  onClick={ ()=>{
                    setStatus('confirm')
                }}>신청 확인/수정</Link>
            </div>
        </div>
    )
}
export default Navbar;