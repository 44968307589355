import { useEffect, useRef, useState } from 'react';
import './QnA.css'

const QnA = ({ setModalOpen })=>{
    const closeModal = () => {
      setModalOpen(false);
    };
    const modalRef = useRef();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])
    useEffect(() => {
        // 이벤트 핸들러 함수
        const handler = (e) => {
            // mousedown 이벤트가 발생한 영역이 모달창이 아닐 때, 모달창 제거 처리
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                setModalOpen(false);
            }
        };
        
        // 이벤트 핸들러 등록
        document.addEventListener('mousedown', handler);
        // document.addEventListener('touchstart', handler); // 모바일 대응
        
        return () => {
            // 이벤트 핸들러 해제
            document.removeEventListener('mousedown', handler);
            // document.removeEventListener('touchstart', handler); // 모바일 대응
        };
    });

    return(
        <div className="QnAOUT">
            <div ref={ modalRef } className="QnA">
                <div className='QnAContent'>
                    <p onClick={ closeModal } className='CloseModal CP'>닫기</p>
                    <div>
                        <p className='ModalTitle'>상담 F&Q</p>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>1. 상담신청 및 진행은 어떻게 되나요?</p>
                            <p className='ModalContent'>성함 및 연락처를 정확히 기입해 주세요<br />신청 후 “신청확인/수정”에서 조회가 가능합니다.</p>
                            <p className='ModalContent'>상담희망날짜 및 상담분류 선택 후 상담내용을 적어주세요.<br />내용이 구체적일수록 상담이 원활하게 진행됩니다.</p>
                            <p className='ModalContent'>신청완료(결제) 후, 사무소에서 1영업일 이내로<br />시간일정조율을 위해 빠르게 확인전화를 드릴 예정입니다.</p>
                            <p className='ModalContent'>상담날짜 조율확인 후, 실제 상담일 시간에 변호사님께서 전화를 드립니다.<br />법무법인 정곡은 고객의 소리를 마음으로 듣고 지성으로 변호해 드립니다.</p>
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>2. 상담희망날짜에 바로 상담할 수 있나요?</p>
                            <p className='ModalContent'>- 고객님께서 요청하신 상담희망날짜에 최대한 상담이 진행됩니다.</p>
                            <p className='ModalContent'>- 상담희망날짜는 신청일로부터 일주일까지 선택 가능합니다.</p>
                            <p className='ModalContent'>- 단, 변호사님의 일정과 상황에 따라 상담날짜가 조정될 수 있으니, 1~2일 정도 여유를 두고 신청해주시길 추천드립니다.</p>
                            <p className='ModalContent'>- 주말 및 공휴일은 휴무입니다.</p>
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>3. 취소 및 환불하는 방법은?</p>
                            <p className='ModalSubTitle_2'>취소</p>
                            <p className='ModalContent'>“신청완료” 단계에서는 언제든지 상담취소가 가능합니다.</p>
                            <p className='ModalContent'>일정조율 전화로 상담일자가 확정이 되었다면 “접수완료” 단계로 변경되며, “접수완료”단계 이후부터는 상담취소 및 환불이 불가합니다.</p>
                            <p className='ModalSubTitle_2'>환불</p>
                            <p className='ModalContent'>신청당일 취소: 즉시환불</p>
                            <p className='ModalContent'>신청일다음날부터 취소: 3~5영업일 소요</p>
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>4. 이름 및 연락처를 잘못 기입하였다면?</p>
                            <p className='ModalContent'>“신청확인/수정”에서 번호조회가 안될 시,<br />02-2602-7377로 전화주시면 확인 도와드리겠습니다.</p>
                        </div>
                        <div className='ModalSection'>
                            <p className='ModalSubTitle'>5. 상담분류별 상세내용</p>
                            <p className='ModalSubTitle_2'>- 부동산</p>
                            <p className='ModalContent'>(부동산 매매, 채무불이행, 손해배상청구, 강제집행, 가압류, 상속, 재산분할 청구 등)</p>
                            <p className='ModalSubTitle_2'>- 이혼 상속 재산분할</p>
                            <p className='ModalContent'>(협의, 재판상이혼, 재산분할청구(부동산, 동산 포함), 상속분(유류분반환)청구, 위자료청구, 양육권분쟁)</p>
                            <p className='ModalSubTitle_2'>- 민사</p>
                            <p className='ModalContent'>(대여금 계약, 채무불이행, 보증금반환청구, 취소무효소송, 손해배상청구, 강제집행, 가압류 및 가처분, 경매, 채권추심, 회생, 파산 채무조정 등)</p>
                            <p className='ModalSubTitle_2'>- 형사</p>
                            <p className='ModalContent'>(사기죄, 폭행및상해죄, 협박죄, 명예훼손죄, 모욕죄, 횡령배임죄, 업무방해, 성범죄, 간통 등, 불기소처분, 집행유예)</p>
                            <p className='ModalSubTitle_2'>- 모름/기타</p>
                            <p className='ModalContent'>(각종 법률적 권리구제 및 법률적 분쟁, 고민 사항)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default QnA;