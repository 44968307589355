import './Reset.css';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Counsel from './pages/Counsel';
import Confirm from './pages/Confirm';
import { Routes, Route } from "react-router-dom";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Result from './pages/Result';
import Complete from './pages/Complete';
import QnA from './components/QnA/QnA';
import { useState } from 'react';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';


function App() {
  const [modalOpen, setModalOpen] = useState(false);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [status, setStatus] = useState('')

  const openModal = () => {
    setModalOpen(true);
  };
  const openPrivacyPolicy = () => {
    setPrivacyPolicyOpen(true);
  };

  return (
    <div className="App">
      <div className='AppContainer'>
        <Header />
        {
          !modalOpen
          ? null
          : <QnA modalOpen={ modalOpen } setModalOpen={ setModalOpen } openModal={ openModal }/>
        }
        {
          !privacyPolicyOpen
          ? null
          : <PrivacyPolicy setPrivacyPolicyOpen={ setPrivacyPolicyOpen }/>
        }
        <Navbar status={status} setStatus={ setStatus }/>
        <Routes>
          <Route path='/' element={<Counsel openModal={ openModal } setStatus={setStatus}/>} />
          <Route path='/counsel' element={<Counsel openModal={ openModal } setStatus={setStatus}/>} />
          <Route path='/confirm' element={<Confirm setStatus={setStatus}/>} />
          <Route path='/result' element={<Result setStatus={setStatus}/>} />
          <Route path='/complete' element={<Complete setStatus={setStatus}/>} />
        </Routes>
      </div>
      <Footer openPrivacyPolicy = { openPrivacyPolicy }/>
    </div>
  );
}

export default App;
